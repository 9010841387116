<template>
  <div class="h-full w-full px-4">
    <portal to="topbar-left">
      <div @click="goBack" class="relative font-bold" key="info-drugcombos-back" :class="{
        'text-white': isCheckedIn,
        'text-brand-dark': !isCheckedIn,
      }">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 22" class="h-6 w-6">
          <g fill="currentColor" fill-rule="evenodd" transform="translate(-4 -1)">
            <path
              d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z" />
          </g>
        </svg>
      </div>
    </portal>

    <h1 class="mb-4 text-3xl font-bold">
      {{ $t("pages.drugcombos") }}
    </h1>

    <div class="flex flex-col space-y-6 rounded-xl p-8">
      <div class="relative flex flex-col items-center justify-center">
        <radial-progress-bar :diameter="260" :completed-steps="weight" :total-steps="totalWeight" :animate-speed="400"
          :inner-stroke-color="isCheckedIn ? '#242D35' : '#39D6C7'" :start-color="startColor" :stop-color="stopColor"
          :stroke-width="12" :inner-stroke-width="12"></radial-progress-bar>

        <div class="absolute inset-0">
          <div class="absolute inset-0 m-0 table h-full w-full p-0">
            <span class="flex h-full max-w-full select-none items-center justify-center px-10 text-center align-middle"
              :class="{ 'font-bold': weight }">
              <span class="w-56" v-if="weight" :style="{ color: stopColor }">{{
              weightLabel ? $t(`drugcombos.risk-label.${weightLabel}`) : "?"
              }}</span>
              <span v-else class="w-56 text-xs">{{
              $t("drugcombos.placeholder--circle")
              }}</span>
            </span>
          </div>
          <div class="absolute inset-0 flex flex-col items-center justify-between" v-if="weight">
            <div class="mt-8 inline flex-shrink-0 text-center text-xs font-semibold leading-3">
              <div v-for="(selectedOpt, selectedIndex) in cleanSelected" :key="selectedOpt"
                class="w-20 truncate font-semibold italic">
                {{
                (selectedIndex ? "+ " : "") + $t(`drug-short.${selectedOpt}`)
                }}
              </div>
            </div>
            <div class="mb-2 h-16 flex-shrink-0">
              <t-button :variant="{ small: isCheckedIn, smallwhite: !isCheckedIn }" class="border text-xs"
                :disabled="!cleanSelected || !cleanSelected.length"
                :style="{ color: stopColor, borderColor: stopColor }" @click="showInfo = true">
                <span class="truncate px-1 text-xs">{{
                $t("drugcombos.btn--more-info")
                }}</span>
              </t-button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col space-y-2">
        <template v-if="cleanSelected && cleanSelected.length">
          <div v-for="(n, index) in cleanSelected" :key="index">
            <div class="flex flex-row space-x-2">
              <div class="flex w-full flex-1 items-center">
                <t-select :options="options_without(selected[index])" v-model="selected[index]"
                  :variant="{ dark: isCheckedIn }" :value="n" @change="onSelectionChange" />
              </div>
              <div class="flex w-12 items-center">
                <t-button type="button" @click="unlinkSelectedIndex(selected[index])" :variant="{ white: isCheckedIn }"
                  :title="$t('drugcombos.btn--remove-option')">
                  <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="sr-only">{{
                  $t("drugcombos.btn--remove-option")
                  }}</span>
                </t-button>
              </div>
            </div>
            <!-- <pre class="text-xs"> {{ index }} | {{ n }} :: {{ selected[index] }}</pre> -->
          </div>
        </template>
        <div v-if="
          !cleanSelected ||
          (cleanSelected &&
            cleanSelected.length < options.length &&
            cleanSelected.length < maxSelection)
        " class="pt-4">
          <t-select :placeholder="
            $t(
              'drugcombos.placeholder--select' +
                (cleanSelected
                  ? cleanSelected.length == 0
                    ? '-first'
                    : /*(cleanSelected.length == 1 ? '-second' :*/ '-another' /*)*/
                  : '')
            )
          " :options="options_append" v-model="lastSelectModel" :variant="{ dark: isCheckedIn }" :value="undefined"
            @change="onLastSelectChange" />
          <!-- <pre class="text-xs"> {{ cleanSelected.length }} | {{ undefined }} :: {{ lastSelectModel }}</pre> -->
        </div>
        <div v-if="cleanSelected && cleanSelected.length" class="pt-4">
          <t-button :variant="{ white: isCheckedIn }" @click="reset">{{
          $t("drugcombos.btn--reset")
          }}</t-button>
        </div>
      </div>
    </div>



    <div class="h-full w-full px-8">
      
        <button class="select-none focus:outline-none items-center transition ease-in-out duration-150 text-white text-center  text-sm font-medium 
          border border-transparent px-2 py-1.5 rounded-3xl w-auto justify-center text-center flex leading-3" style="width:100%;height:10%;background-color: rgba(0,0,0, 0.4)"> 
          
            <span class="text-xs" style="width:75%;font-size:86%">
              {{$t("drugcombos.placeholder--info")}}
            </span>

        </button>
      
    </div>

    <popup-pane v-if="showInfo" :title="$t(`drugcombos.risk-label.${weightLabel}`)" @close="showInfo = false"
      :dark="isCheckedIn">
      <p>{{ $t(`drugcombos.risk-description.${weightLabel}`) }}</p>

      <div v-if="combinations && orderedCombinations" class="mt-6 space-y-6">
        <hr class="mb-2 w-24 border-brand-lightblue" />

        <div v-for="(combined, combindex) in orderedCombinations" :key="combindex">
          <div class="text-lg font-semibold">
            <span v-for="(combined_drug, combined_drug_i) in combined.via" :key="`${combindex}__drug-${combined_drug}`"
              class="block text-sm 2xs:text-base">
              {{ combined_drug_i > 0 ? " + " : "" }}
              {{ $t(`drug.${combined_drug}`) }}
            </span>
            <div class="block text-base font-bold" :style="{ color: combined.stopColor }">
              {{ $t(`drugcombos.risk-label.${combined.risk}`) }}
            </div>
          </div>
          <div class="text-sm">
            {{ $t(`drugcombos.combo-description.${combined.note}`) }}
          </div>
        </div>
      </div>
      <div class="mt-10 text-sm">
        <hr class="mb-2 w-24 border-brand-lightblue" />
        <p>
          Looking for more? Check out
          <t-button variant="link" class="text-brand-primary" href="https://combo.tripsit.me/" target="_blank">Tripsit
          </t-button>.
        </p>
      </div>
    </popup-pane>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Repository from "repositories/RepositoryFactory";
const DrugRepository = Repository.get("drug");

import PopupPane from "components/PopupPane";
import RadialProgressBar from "vue-radial-progress";

export default {
  name: "info-drugcombos",
  components: {
    RadialProgressBar,
    PopupPane,
  },
  data: () => ({
    selected: [],
    lastSelectModel: null,
    showInfo: false,
    maxSelection: 4,
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    reset() {
      this.selected = [];
      this.onSelectionChange();
    },
    onSelectionChange() {
      this.selected = Object.values(this.cleanSelected);
      this.lastSelectModel = null;
    },
    onLastSelectChange() {
      if (!this.lastSelectModel) return;
      this.selected.push(this.lastSelectModel);
      this.selected = this.cleanSelected;
      this.lastSelectModel = null;
    },
    highestWeight(ofThisArray) {
      if (!ofThisArray || !(ofThisArray instanceof Array)) return 0;
      return Math.max.apply(
        Math,
        ofThisArray.map(function (o) {
          return o.weight;
        })
      );
    },
    findCombinationKeys(arr) {
      let cc = new Object();
      if (!arr || !(arr instanceof Array) || arr?.length < 2) return cc;
      arr;
      arr.forEach((k1) => {
        arr.forEach((k2) => {
          if (k1 != k2) {
            var sorted_keys = [k1, k2].sort(function (a, b) {
              return a > b ? 1 : -1;
            });
            var sorted_keys_str = sorted_keys.join("___");
            let found = DrugRepository.combo(...sorted_keys);
            if (found) {
              const risk_data = DrugRepository.risk(found.risk);
              found.via = sorted_keys;
              found.weight = risk_data.weight;
              found.startColor = risk_data.startColor;
              found.stopColor = risk_data.stopColor;
              cc[`${sorted_keys_str}`] = found;
            }
          }
        });
      });
      return cc;
    },

    unlinkSelectedIndex(key) {
      var index = this.selected.indexOf(key);
      if (index > -1) {
        this.selected.splice(index, 1);
      }
      this.onSelectionChange();
    },

    options_without(selectedKey) {
      let opts = [];
      this.drugs.forEach((drug) => {
        if (this.selected.includes(drug.key) && selectedKey !== drug.key)
          return;
        opts.push({
          label: this.$i18n.t(`drug.${drug.key}`),
          value: drug.key,
          disabled: this.selected.includes(drug.key),
        });
      });
      return opts.sort((a, b) => {
        return a.label < b.label;
      });
    },
  },
  computed: {
    ...mapGetters(["isCheckedIn"]),
    stopColor() {
      if (this.outcome) return this.outcome.stopColor;
      return "#CCCCCC";
    },
    startColor() {
      if (this.outcome) return this.outcome.startColor;
      return "#DDDDDD";
    },
    totalWeight() {
      if (!this.risks) return 0;
      return this.highestWeight(Object.values(this.risks));
    },
    weightLabel() {
      if (this.outcome) return this.outcome.risk;
      return "unknown";
    },
    weight() {
      if (this.outcome) return this.outcome.weight;
      /* return Math.min(this.cleanSelected
        .reduce(function(prev, cur) {
          console.log('reducing', cur, 'prev:', prev)
          const opt = this.drugs.find(opt => opt.key === cur)
          console.log('found?', opt)
          if(!opt) return prev;
          return prev + (opt.weight || 0);
        }.bind(this), 0), this.totalWeight) */

      return 0;
    },
    cleanSelected() {
      return this.selected
        ? Object.values(
          this.selected.filter((_) => {
            return !!_;
          })
        )
        : [];
    },

    options() {
      let opts = [];
      this.drugs.forEach((drug) => {
        opts.push({
          label: this.$i18n.t(`drug.${drug.key}`),
          value: drug.key,
          disabled: this.selected.includes(drug.key),
        });
      });
      return opts.sort((a, b) => {
        return a.label < b.label;
      });
    },

    options_append() {
      let opts = [];
      this.drugs.forEach((drug) => {
        if (this.selected.includes(drug.key)) return;
        opts.push({
          label: this.$i18n.t(`drug.${drug.key}`),
          value: drug.key,
          disabled: this.selected.includes(drug.key),
        });
      });
      return opts.sort((a, b) => {
        return a.label < b.label;
      });
    },

    outcome() {
      if (!this.combinations || !this.orderedCombinations) return null;
      const [first] = this.orderedCombinations;
      return first;
    },
    risks() {
      return DrugRepository.risks() || [];
    },
    risk() {
      if (this.outcome) return DrugRepository.risk(this.outcome.risk);
      return null;
    },
    drugs() {
      return DrugRepository.drugs() || [];
    },
    combos() {
      return DrugRepository.combos() || [];
    },
    combinations() {
      if (!this.cleanSelected) return [];
      return this.findCombinationKeys(this.cleanSelected);
    },
    orderedCombinations() {
      if (!this.combinations) return [];
      return Object.values(this.combinations).sort((a, b) =>
        a.weight < b.weight ? 1 : -1
      );
    },
  },
  watch: {
    // whenever question changes, this function will run
    showInfo: function (newState) {
      if (newState && this.$gtm && this.$gtm.enabled()) {
        this.$gtm.trackEvent({
          category: "ChemCombo",
          action: "click",
          label: "Opened combo for more info",
          value: this.orderedCombinations,
        });
      }
    },
  },
  metaInfo() {
    return {
      title: `${this.$i18n.t("pages.drugcombos")}`,
    };
  },
};
</script>
